import { reactive, ref } from 'vue'
export const chinaContentInfo = reactive([
  {
    title: 'Administrative Penalties',
    labelStyle: { width: '112px', minWidth: '112px' },
    dataSourceKey: 'administrativePenaltyList',
    list: [
      {
        title: 'Reference No', // 决定文书号
        htmlDomFunc: ({ referenceNumber }: any) => {
          return `<div class="value">${referenceNumber || 'No records found'}</div>`
        }
      },
      {
        title: 'Penalty Date', // 处罚决定日期
        htmlDomFunc: ({ penaltyDate }: any) => {
          return `<div class="value">${penaltyDate || 'No records found'}</div>`
        }
      },
      {
        title: 'Authority', // 决定机关名称
        htmlDomFunc: ({ penaltyAuthority, penaltyAuthorityOrg }: any) => {
          if (!penaltyAuthority && !penaltyAuthorityOrg) {
            return '<div class="value">No records found</div>'
          }
          return (
            `<div class="value">
              <div>${penaltyAuthority}</div>
              <div>${penaltyAuthorityOrg}</div>
            </div>`
          )
        }
      },
      {
        title: 'Reason', // 违法行为
        key: 'penaltyReason',
        htmlDomFunc: ({ penaltyReason, penaltyReasonOrg }: any) => {
          if (!penaltyReason && !penaltyReasonOrg) {
            return '<div class="value">No records found</div>'
          }
          return (
            `<div class="value">
              <div>${penaltyReason}</div>
              <div>${penaltyReasonOrg}</div>
            </div>`
          )
        }
      }
    ]
  },
  {
    title: 'Abnormal Operations',
    dataSourceKey: 'abnormalOperationsList',
    headers: [
      {
        title: 'Listing Date',
        key: 'aoListingDate',
        width: 120
      },
      {
        title: 'Listing Authority',
        htmlDomFunc: ({ aoListingAuthority, aoListingAuthorityOrg }: any) => {
          if (!aoListingAuthority && !aoListingAuthorityOrg) {
            return '<div class="value">No records found</div>'
          }
          return `
          <div class="value">
            <div>${aoListingAuthority}</div>
            <div>${aoListingAuthorityOrg}</div>
          </div>`
        }
      },
      {
        title: 'Reason',
        htmlDomFunc: ({ aoListingReason, aoListingReasonOrg }: any) => {
          if (!aoListingReason && !aoListingReasonOrg) {
            return '<div class="value">No records found</div>'
          }
          return `
          <div class="value">
            <div>${aoListingReason}</div>
            <div>${aoListingReasonOrg}</div>
          </div>`
        }
      }
    ]
  },
  {
    title: 'Serious Irregularities',
    dataSourceKey: 'seriousViolationList',
    headers: [
      {
        title: 'Listing Date',
        key: 'svListingDate',
        width: 120
      },
      {
        title: 'Listing Authority',
        htmlDomFunc: ({ svListingAuthority, svListingAuthorityOrg }: any) => {
          if (!svListingAuthority && !svListingAuthorityOrg) {
            return '<div class="value">No records found</div>'
          }
          return `
          <div class="value">
            <div>${svListingAuthority}</div>
            <div>${svListingAuthorityOrg}</div>
          </div>`
        }
      },
      {
        title: 'Reason',
        htmlDomFunc: ({ svListingReason, svListingReasonOrg }: any) => {
          if (!svListingReason && !svListingReasonOrg) {
            return '<div class="value">No records found</div>'
          }
          return `
          <div class="value">
            <div>${svListingReason}</div>
            <div>${svListingReasonOrg}</div>
          </div>`
        }
      }
    ]
  },
  {
    title: 'Equity Pledges',
    labelStyle: { width: '132px' },
    dataSourceKey: 'equityPledgeList',
    list: [
      {
        title: 'Registration No.',
        htmlDomFunc: ({ epRegistrationNumber }: any) => {
          return `<div class="value">${epRegistrationNumber || 'No records found'}</div>`
        }
      },
      {
        title: 'Pledgor',
        canClick: ({ pledgorType }: any) => +pledgorType === 1,
        nameKey: 'pledgorOrg',
        htmlDomFunc: ({ pledgor, pledgorOrg }: any) => {
          if (!pledgor && !pledgorOrg) {
            return 'No records found'
          }
          return (
            `<div>${pledgor}</div>
              <div>${pledgorOrg}</div>`
          )
        }
      },
      {
        title: 'Pledgee',
        canClick: ({ pledgeeType }: any) => +pledgeeType === 1,
        nameKey: 'pledgeeOrg',
        htmlDomFunc: ({ pledgee, pledgeeOrg }: any) => {
          if (!pledgee && !pledgeeOrg) {
            return 'No records found'
          }
          return (
            `<div>${pledgee}</div>
              <div>${pledgeeOrg}</div>`
          )
        }
      },
      {
        title: 'Target Company',
        canClick: ({ targetCompanyType }: any) => +targetCompanyType === 1,
        nameKey: 'targetCompanyOrg',
        htmlDomFunc: ({ targetCompany, targetCompanyOrg }: any) => {
          if (!targetCompany && !targetCompanyOrg) {
            return 'No records found'
          }
          return (
            `<div>${targetCompany}</div>
              <div>${targetCompanyOrg}</div>`
          )
        }
      },
      {
        title: 'Pledged Equities',
        htmlDomFunc: ({ equityAmount }: any) => {
          return (
            `<div class="value">
             ${((+equityAmount?.amount) ? `${equityAmount.amount || ''} ${equityAmount.currency}` : 'No records found')}
            </div>`
          )
        }
      },
      {
        title: 'Registration Date',
        htmlDomFunc: ({ epDate }: any) => {
          return `<div class="value">${epDate || 'No records found'}</div>`
        }
      },
      {
        title: 'Status',
        htmlDomFunc: ({ epStatus }: any) => {
          return `<div class="value">${epStatus || 'No records found'}</div>`
        }
      }
    ]
  }
])
