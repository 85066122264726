import { reactive } from 'vue'
export const vipTypeInfoList = reactive([
  {
    title: 'Registration Information',
    deep: true,
    pro: true
  },
  {
    title: 'Shareholders',
    deep: true,
    pro: true
  },
  {
    title: 'Officers',
    deep: true,
    pro: true
  },
  {
    title: 'Contact Information of Chinese Companies',
    deep: true,
    pro: true
  },
  {
    title: 'Alerts',
    deep: false,
    pro: true
  },
  {
    title: 'Financial Information ',
    deep: false,
    pro: true
  },
  {
    title: 'Investments Information',
    deep: false,
    pro: true
  },
  {
    title: 'Change Records',
    deep: false,
    pro: true
  },
  {
    title: 'Branch Information',
    deep: false,
    pro: true
  },
  {
    title: 'Workplace Information',
    deep: false,
    pro: true
  },
  {
    title: 'Monitor Companies',
    deepNum: 10,
    proNum: 20
  },
  {
    title: 'Export PDF and Excel  Reports',
    deep: true,
    pro: true
  },
  {
    title: 'No daily Verification Limit',
    deep: true,
    pro: true
  },
  {
    title: 'Verification of Up to 100 Companies/Month',
    deep: true,
    pro: true
  }
])
