import { reactive } from 'vue'
export const chinaContentInfo = reactive([
  {
    title: 'Branches',
    dataSourceKey: 'branchList',
    headers: [
      {
        title: 'Branch Name',
        canClick: true,
        htmlDomFunc: ({ branchName, branchNameOrg }: any) => {
          if (!branchName && !branchNameOrg) {
            return false
          }
          return `
            <div>${branchName}</div>
            <div>${branchNameOrg}</div>`
        }
      },
      {
        title: 'Location',
        key: 'location',
        width: 120
      },
      {
        title: 'Date of Establishment',
        key: 'establishmentDate',
        width: 160
      },
      {
        title: 'Status',
        htmlDomFunc: ({ branchStatusOrg }: any) => {
          if (!branchStatusOrg) {
            return '<div class="value">No records found</div>'
          }
          return `<div class="status ${branchStatusOrg}">
            <div class="icon"><i /></div>
            <div>${branchStatusOrg}</div>
        </div>`
        },
        width: 150
      }
    ]
  },
  {
    title: 'Investments',
    dataSourceKey: 'investmentList',
    headers: [
      {
        title: 'Invested Company Name',
        canClick: true,
        htmlDomFunc: ({ investName, investNameOrg }: any) => {
          if (!investName && !investNameOrg) {
            return false
          }
          return `
            <div>${investName}</div>
            <div>${investNameOrg}</div>`
        }
      },
      {
        title: 'Shareholding',
        key: 'investShareholding',
        width: 120
      },
      {
        title: 'Date of Establishment',
        key: 'investTime',
        width: 160
      },
      {
        title: 'Status',
        htmlDomFunc: ({ investStatusOrg }: any) => {
          if (!investStatusOrg) {
            return '<div class="value">No records found</div>'
          }
          return `<div class="status ${investStatusOrg}">
            <div class="icon"><i /></div>
            <div>${investStatusOrg}</div>
        </div>`
        },
        width: 150
      }
    ]
  }
])

export const taiwanContentInfo = reactive([
  {
    title: 'Branch Information',
    dataSourceKey: 'branchList',
    headers: [
      {
        title: 'Branch Name',
        canClick: true,
        htmlDomFunc: ({ branchName, branchNameOrg }: any) => {
          if (!branchName && !branchNameOrg) {
            return false
          }
          return `
            <div>${branchName}</div>
            <div>${branchNameOrg}</div>`
        }
      },
      {
        title: 'Registration Number',
        key: 'branchNumber',
        width: 160
      },
      {
        title: 'Date of Establishment',
        key: 'establishmentDate',
        width: 160
      },
      {
        title: 'Status',
        htmlDomFunc: ({ branchStatusOrg }: any) => {
          if (!branchStatusOrg) {
            return '<div class="value">No records found</div>'
          }
          return `<div class="status ${branchStatusOrg}">
            <div class="icon"><i /></div>
            <div>${branchStatusOrg}</div>
        </div>`
        },
        width: 150
      }
    ]
  }
])
