import { reactive } from 'vue'
// 中国Registration上方块状数据结构
export const chinaRegistrationItemIconInfo = reactive([
  {
    src: require('../../assets/search/icon_Status.png'),
    value: ({ status }: any) => status || 'No records found',
    tootip: 'Status',
    style: { width: '132px' }
  },
  {
    src: require('../../assets/search/icon_Date.png'),
    value: ({ establishingDate }: any) => establishingDate || 'No records found',
    tootip: 'Date of Establishment',
    style: { width: '154px' }
  },
  {
    src: require('../../assets/search/icon_Capital.png'),
    value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital.currency}` : 'No records found'),
    tootip: 'Capital',
    style: { width: '136px' }
  },
  {
    src: require('../../assets/search/icon_Legal.png'),
    value: ({ representative }: any) => representative || 'No records found',
    tootip: 'Legal Representative',
    style: { width: '152px' }
  },
  {
    src: require('../../assets/search/icon_Location.png'),
    value: ({ province }: any) => province || 'No records found',
    tootip: 'Location',
    style: { width: '132px' }
  },
  {
    src: require('../../assets/search/icon_Unified.png'),
    value: ({ creditCode }: any) => creditCode || 'No records found',
    tootip: 'Unified Social Credit Identifier',
    style: { width: '206px' }
  }
])
// 台湾Registration上方块状数据结构
export const taiwanRegistrationItemIconInfo = reactive([
  {
    src: require('../../assets/search/icon_Status.png'),
    value: ({ status }: any) => status || 'No records found',
    tootip: 'Status'
  },
  {
    src: require('../../assets/search/icon_Date.png'),
    value: ({ establishingDate }: any) => establishingDate || 'No records found',
    tootip: 'Date of Establishment'
  },
  {
    src: require('../../assets/search/icon_Capital.png'),
    value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital.currency}` : 'No records found'),
    tootip: 'Capital'
  },
  {
    src: require('../../assets/search/icon_Legal.png'),
    value: ({ representative }: any) => representative || 'No records found',
    tootip: 'Legal Representative'
  },
  {
    src: require('../../assets/search/icon_Unified.png'),
    value: ({ registrationNumber }: any) => registrationNumber || 'No records found',
    tootip: 'Registration Number'
  }
])
// 日本Registration上方块状数据结构
export const japanRegistrationItemIconInfo = reactive([
  {
    src: require('../../assets/search/icon_Status.png'),
    value: ({ status }: any) => ((!status || status === '-') ? 'Active' : status),
    tootip: 'Status'
  },
  {
    src: require('../../assets/search/icon_Date.png'),
    value: ({ establishingDate }: any) => establishingDate || 'No records found',
    tootip: 'Date of Establishment'
  },
  {
    src: require('../../assets/search/icon_Capital.png'),
    value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital.currency}` : 'No records found'),
    tootip: 'Capital'
  },
  {
    src: require('../../assets/search/icon_Unified.png'),
    value: ({ registrationNumber }: any) => registrationNumber || 'No records found',
    tootip: 'Registration Number'
  }
])
// 中国Shareholders上方块状数据结构
export const chinaShareholdersItemIconInfo = reactive([
  {
    value: ({ shareholdersNum }: any) => shareholdersNum || 'No records found',
    tootip: 'Number of Shareholders',
    style: { width: '182px' }
  },
  {
    value: ({ officerNum }: any) => officerNum || 'No records found',
    tootip: 'Number of Officers',
    style: { width: '154px' }
  },
  {
    value: ({ chairman }: any) => chairman || 'No records found',
    tootip: 'Chairman',
    style: { width: '136px' }
  },
  {
    value: ({ largestShareholder }: any) => largestShareholder || 'No records found',
    tootip: 'Largest Shareholder',
    style: { minWidth: '180px', maxWidth: '430px' }
  }
])
// 台湾Shareholders上方块状数据结构
export const taiwanShareholdersItemIconInfo = reactive([
  {
    value: ({ shareholdersNum }: any) => shareholdersNum || 'No records found',
    tootip: 'Number of Shareholders',
    style: { width: '169px' }
  },
  {
    value: ({ directorsNum }: any) => directorsNum || 'No records found',
    tootip: 'Number of Officers',
    style: { width: '154px' }
  },
  {
    value: ({ managerNum }: any) => managerNum || 'No records found',
    tootip: 'Number of Manager',
    style: { width: '150px' }
  },
  {
    value: ({ chairman }: any) => chairman || 'No records found',
    tootip: 'Chairman',
    style: { width: '136px' }
  },
  {
    value: ({ largestShareholder }: any) => largestShareholder || 'No records found',
    tootip: 'Largest Shareholder',
    style: { width: '150px' }
  }
])
// 日本Shareholders上方块状数据结构
export const japanShareholdersItemIconInfo = reactive([
  {
    value: ({ shareholdersNum }: any) => shareholdersNum || 'No records found',
    tootip: 'Number of Shareholders',
    style: { width: '182px' }
  },
  {
    value: ({ officerNum }: any) => officerNum || 'No records found',
    tootip: 'Number of Officers',
    style: { width: '154px' }
  },
  {
    value: ({ representative }: any) => representative || 'No records found',
    tootip: 'Legal Representative',
    style: { width: '206px' }
  },
  {
    value: ({ largestShareholder }: any) => largestShareholder || 'No records found',
    tootip: 'Largest Shareholder',
    style: { width: '302px' }
  }
])
// 中国Alerts上方块状数据结构
export const chinaAlertsItemIconInfo = reactive([
  {
    value: ({ administrativePenaltyNum }: any) => administrativePenaltyNum || 'No records found',
    tootip: 'Number of Administrative Penalties',
    style: { width: '242px' }
  },
  {
    value: ({ abnormalOperationNum }: any) => abnormalOperationNum || 'No records found',
    tootip: 'Number of Abnormal Operations',
    style: { width: '218px' }
  },
  {
    value: ({ seriousViolationNum }: any) => seriousViolationNum || 'No records found',
    tootip: 'Number of Serious Irregularities',
    style: { width: '236px' }
  },
  {
    value: ({ equityPledgeNum }: any) => equityPledgeNum || 'No records found',
    tootip: 'Number of Equity Pledges',
    style: { width: '182px' }
  }
])
// 中国Branch上方块状数据结构
export const chinaBranchItemIconInfo = reactive([
  {
    value: ({ branchNum }: any) => branchNum || 'No records found',
    tootip: 'Number of Branches',
    style: { width: '182px' }
  },
  {
    value: ({ investNum }: any) => investNum || 'No records found',
    tootip: 'Number of Investment',
    style: { width: '184px' }
  }
])
// 台湾Branch上方块状数据结构
export const taiwanBranchItemIconInfo = reactive([
  {
    value: ({ branchNum }: any) => branchNum || 'No records found',
    tootip: 'Number of Branches',
    style: { width: '182px' }
  }
])
// 中国Change上方块状数据结构
export const chinaChangeItemIconInfo = reactive([
  {
    value: ({ changeNum }: any) => changeNum || 'No records found',
    tootip: 'Number of Changes',
    style: { width: '182px' }
  }
])

// 中国tabItem数据结构
export const chinaTabItemInfo = reactive([
  {
    label: 'Registration Information',
    key: 'Registration'
  },
  {
    label: 'Shareholders & Officers',
    key: 'Shareholders'
  },
  {
    label: 'Alerts',
    key: 'Alerts'
  },
  {
    label: 'Branch & Investments',
    key: 'Branch'
  },
  {
    label: 'Change Records',
    key: 'Change'
  }
])
// 台湾tabItem数据结构
export const taiwanTabItemInfo = reactive([
  {
    label: 'Registration Information',
    key: 'Registration'
  },
  {
    label: 'Shareholders & Officers',
    key: 'Shareholders'
  },
  {
    label: 'Branch Information',
    key: 'Branch'
  }
])
// 日本tabItem数据结构
export const japanTabItemInfo = reactive([
  {
    label: 'Registration Information',
    key: 'Registration'
  },
  {
    label: 'Shareholders & Officers',
    key: 'Shareholders'
  },
  {
    label: 'Financial Information & Workplace Information',
    key: 'Financial'
  }
])
