import { reactive } from 'vue'
export const chinaContentInfo = reactive([
  {
    title: 'Shareholders',
    key: 'shareholdersList',
    headers: [
      {
        title: 'Proportion',
        key: 'proportion',
        width: 110
      },
      {
        title: 'Subscribed Capital', // 认缴金额
        htmlDomFunc: ({ subscribedCapital }: any) => {
          return `<div class="value">${((+subscribedCapital?.amount || subscribedCapital?.amount === 0) ? `${subscribedCapital.amount || ''} ${subscribedCapital?.currency}` : 'No records found')}</div>`
        },
        width: 140
      },
      {
        title: 'Subscribed Date',
        key: 'subscribedDate', // 认缴日期
        width: 135
      },
      {
        title: 'Paid - Up Capital', // 实缴金额
        htmlDomFunc: ({ paidUpCapital }: any) => {
          return `<div class="value">${((+paidUpCapital?.amount || +paidUpCapital?.amount === 0) ? `${paidUpCapital.amount || ''} ${paidUpCapital?.currency}` : 'No records found')}</div>`
        },
        width: 135
      },
      {
        title: 'Paid - Up Date',
        key: 'paidUpDate', // 实缴日期
        width: 135
      }
    ]
  },
  {
    title: 'Officers',
    nameKey: 'Officers',
    dataSourceKey: 'officersList',
    htmlDomFunc: ({ name, nameOrg, position, positionOrg }: any) => {
      return `<div class="img"><img src="${require('@/assets/companyInfo/photo.png')}"/></div>
      <div class="name">${name || ''} ${nameOrg || ''}</div>
      <div>${position || ''}</div>
      <div>${positionOrg || ''}</div>`
    }
  }
])
export const japanContentInfo = reactive([
  {
    title: 'Major Shareholders',
    key: 'majorShareholdersList',
    headers: [
      {
        title: 'Proportion',
        key: 'proportion'
      }
    ]
  },
  {
    title: 'Representative & Directors',
    nameKey: 'Officers',
    dataSourceKey: 'officersList',
    htmlDomFunc: ({ representative, representativeOrg }: any) => {
      return `<div class="img"><img src="${require('@/assets/companyInfo/photo.png')}"/></div>
      <div>${representative}</div>
      <div>${representativeOrg}</div>`
    }
  }
])
export const taiwanContentInfo = reactive([
  {
    title: 'Major Shareholders',
    key: 'majorShareholdersList',
    headers: [
      {
        title: 'Proportion',
        key: 'proportion'
      }
    ]
  },
  {
    title: 'Directors & Supervisors',
    nameKey: 'Officers',
    dataSourceKey: 'directorsList',
    htmlDomFunc: ({ name, nameOrg, position, positionOrg }: any) => {
      return `<div class="img"><img src="${require('@/assets/companyInfo/photo.png')}"/></div>
      <div class="name">${name || ''} ${nameOrg || ''}</div>
      <div>${position || ''}</div>
      <div>${positionOrg || ''}</div>`
    }
  },
  {
    title: 'Manager',
    htmlDomFunc: ({ name, nameOrg, position, positionOrg }: any) => {
      return `<div class="img"><img src="${require('@/assets/companyInfo/photo.png')}"/></div>
      <div class="name">${name || ''} ${nameOrg || ''}</div>
      <div>${position || ''}</div>
      <div>${positionOrg || ''}</div>`
    }
  }
])
