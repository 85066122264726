import { reactive } from 'vue'
export const chinaContentInfo = reactive([
  {
    title: 'Change Records',
    dataSourceKey: 'changeList',
    headers: [
      {
        title: 'Changed Item',
        htmlDomFunc: ({ changeItem, changeItemOrg }: any) => {
          if (!changeItem && !changeItemOrg) {
            return '<div class="value">No records found</div>'
          }
          return `
          <div class="value">
            <div>${changeItem || ''}</div>
            <div>${changeItemOrg || ''}</div>
          </div>`
        }
      },
      {
        title: 'Change Date',
        key: 'changeDate',
        width: 120
      },
      {
        title: 'Before',
        htmlDomFunc: ({ changeBefore, changeBeforeOrg }: any) => {
          if (!changeBefore && !changeBeforeOrg) {
            return '<div class="value">No records found</div>'
          }
          // 重复只展示一次
          if (changeBefore === changeBeforeOrg) {
            return `<div class="value">
              <div>${changeBefore || ''}</div>
            </div>`
          }
          return `
          <div class="value">
            <div>${changeBefore || ''}</div>
            <div>${changeBeforeOrg || ''}</div>
          </div>`
        }
      },
      {
        title: 'After',
        htmlDomFunc: ({ changeAfter, changeAfterOrg }: any) => {
          if (!changeAfter && !changeAfterOrg) {
            return '<div class="value">No records found</div>'
          }
          // 重复只展示一次
          if (changeAfter === changeAfterOrg) {
            return `<div class="value">
              <div>${changeAfter || ''}</div>
            </div>`
          }
          return `
          <div class="value">
            <div>${changeAfter || ''}</div>
            <div>${changeAfterOrg || ''}</div>
          </div>`
        }
      }
    ]
  }
])
