import { reactive } from 'vue'
export const japanContentInfo = reactive([
  {
    title: 'Financial Information',
    dataSourceKey: 'financialList',
    headers: [
      {
        title: 'Round',
        key: 'round',
        width: 70
      },
      {
        title: 'Sales Amount',
        key: 'salesAmount.amount',
        width: 130
      },
      {
        title: 'Ordinary Income Or Ordinary Loss',
        key: 'ordinaryIncomeOrLoss.amount',
        width: 250
      },
      {
        title: 'Net Income or Net Loss',
        key: 'netProfitOrLoss.amount',
        width: 190
      },
      {
        title: 'Net Assets',
        key: 'netAssets.amount',
        width: 140
      },
      {
        title: 'Total Assets',
        key: 'totalAssets.amount'
      }
    ]
  },
  {
    title: 'Workplace Information',
    labelStyle: { width: '310px' },
    className: 'column-2',
    dataSourceKey: 'workplaceInfo',
    list: [
      {
        title: 'Average Years of Continuous Service(Male)',
        key: 'averageContinuousServiceYearsMale'
      },
      {
        title: 'Average Years of Continuous Service(Female)',
        key: 'averageContinuousServiceYearsFemale'
      },
      {
        title: 'Number of Female Managers',
        key: 'numberOfFemaleManagers'
      },
      {
        title: 'Number of Female Officers',
        key: 'numberOfFemaleOfficers'
      },
      {
        title: 'Total Number of Officers',
        key: 'totalNumberOfOfficers'
      },
      {
        title: 'Female Workers Proportion',
        key: 'femaleWorkersProportion'
      }
    ]
  }
])
