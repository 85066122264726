import { reactive } from 'vue'
export const chinaContentInfo = reactive([
  {
    title: 'Basic Information',
    className: 'column-2',
    list: [
      {
        title: 'Unified Social Credit Identifier:',
        key: 'creditCode',
        isCopy: true
      },
      {
        title: 'Registration Number:',
        key: 'registrationNumber',
        isCopy: true
      },
      {
        title: 'Organization Code:',
        key: 'organizationCode',
        isCopy: true
      },
      {
        title: 'Date of Establishment:',
        value: ({ establishingDate }: any) => establishingDate
      },
      {
        title: 'Approved Date:',
        value: ({ lastUpdateDate }: any) => lastUpdateDate
      },
      {
        title: 'Term of Operation:',
        key: 'termOfOperation' // 不确定
      },
      {
        title: 'Date of Cancellation:',
        key: 'cancellationDate'
      },
      {
        title: 'Date of Revoke:',
        key: 'revokeDate'
      }
    ]
  },
  {
    title: 'Legal Representative',
    slot: 'legal'
  },
  {
    title: 'Status',
    htmlDomFunc: ({ status, statusOrg }: any) => {
      if (!status && !statusOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'status'
      },
      {
        title: '',
        key: 'statusOrg'
      }
    ]
  },
  {
    title: 'Company Size',
    list: [
      {
        title: 'Registered Capital:',
        value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital.currency}` : undefined)
      },
      {
        title: 'Paid-Up Capital:',
        value: ({ paidInCapital }: any) => ((+paidInCapital?.amount) ? `${paidInCapital.amount || ''} ${paidInCapital.currency}` : undefined)
      },
      {
        title: 'Number of Employees:',
        key: 'staffSize' // 不确定
      }
    ]
  },
  {
    title: 'Type',
    htmlDomFunc: ({ type, typeOrg }: any) => {
      if (!type && !typeOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'type'
      },
      {
        title: '',
        key: 'typeOrg'
      }
    ]
  },
  {
    title: 'Former Name',
    htmlDomFunc: ({ formerNameList }: any) => {
      if (!formerNameList || !formerNameList.length) {
        return '<div class="value">No records found</div>'
      }
      const _list = formerNameList.map((item: any) => `<div>${item?.name} (${item?.nameOrg})</div>`)
      return `<div class="value">${_list.join('')}</div>`
    }
  },
  {
    title: 'Registration Authority',
    htmlDomFunc: ({ registerOffice, registerOfficeOrg }: any) => {
      if (!registerOffice && !registerOfficeOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'registerOffice'
      },
      {
        title: '',
        key: 'registerOfficeOrg'
      }
    ]
  },
  {
    title: 'Contact Information',
    slot: 'contact-information',
    list: [
      {
        title: 'Phone Number: ',
        isCopy: true,
        key: 'phone'
      },
      {
        title: 'Website: ',
        isCopy: true,
        key: 'website'
      },
      {
        title: 'Email: ',
        isCopy: true,
        key: 'email'
      },
      {
        title: 'Address: '
      }
    ]
  },
  {
    title: 'Industry',
    htmlDomFunc: ({ industry, industryOrg }: any) => {
      if (!industry && !industryOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'industry'
      },
      {
        title: '',
        key: 'industryOrg'
      }
    ]
  },
  {
    title: 'Business Scope',
    htmlDomFunc: ({ businessScope, businessScopeOrg }: any) => {
      if (!businessScope && !businessScopeOrg) {
        return '<div class="value">No records found</div>'
      }
      const businessScopeList = businessScope.map((item:string) => `<div>${item}</div>`)
      const businessScopeOrgList = businessScopeOrg.map((item:string) => `<div>${item}</div>`)
      return `<div class="value">
        ${businessScopeList.join('')}
        <br/>
        ${businessScopeOrgList.join('')}
      </div>`
    }
  }
])
export const japanContentInfo = reactive([
  {
    title: 'Basic Information',
    className: 'column-2',
    list: [
      {
        title: 'Kana Name:',
        key: 'kanaName',
        isCopy: true
      },
      {
        title: 'Registration Number:',
        key: 'registrationNumber',
        isCopy: true
      },
      {
        title: 'Date of Establishment:',
        key: 'establishingDate'
      },
      {
        title: 'Last Modification Date:',
        key: 'lastUpdateDate'
      },
      {
        title: 'Date of Cancellation:',
        key: 'cancellationDate'
      },
      {
        title: 'Reason of Cancellation:',
        key: 'cancellationReason' // 不确定
      }
    ]
  },
  {
    title: 'Status',
    htmlDomFunc: ({ status, statusOrg }: any) => {
      status = status || '-'
      statusOrg = statusOrg || '-'
      if (status === '-' && statusOrg === '-') {
        return '<div class="value">Active</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        value: ({ status }: any) => ((!status || status === '-') ? 'Active' : status)
      },
      {
        title: '',
        value: ({ statusOrg }: any) => ((!statusOrg || statusOrg === '-') ? 'Active' : statusOrg)
      }
    ]
  },
  {
    title: 'Company Size',
    list: [
      {
        title: 'Registered Capital:',
        value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital.currency}` : undefined)
      },
      {
        title: 'Number of Employees:',
        key: 'employeeNumber'
      },
      {
        title: 'Number of Male Employees:',
        value: ({ companySizeDetails }: any) => companySizeDetails?.numberOfMale
      },
      {
        title: 'Number of Female Employees:',
        value: ({ companySizeDetails }: any) => companySizeDetails?.numberOfFemale
      }
    ]
  },
  {
    title: 'Type',
    htmlDomFunc: ({ type, typeOrg }: any) => {
      if (!type && !typeOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'type'
      },
      {
        title: '',
        key: 'typeOrg'
      }
    ]
  },
  {
    title: 'Contact Information',
    slot: 'contact-information',
    list: [
      {
        title: 'Website: ',
        isCopy: true,
        key: 'website'
      },
      {
        title: 'Postal Code: ',
        isCopy: true,
        key: 'postalCode'
      },
      {
        title: 'Address: '
      }
    ]
  },
  {
    title: 'Business Summary',
    htmlDomFunc: ({ businessSummary, businessSummaryOrg }: any) => {
      if (!businessSummary && !businessSummaryOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'businessSummary'
      },
      {
        title: '',
        key: 'businessSummaryOrg'
      }
    ]
  },
  {
    title: 'Business Scope',
    htmlDomFunc: ({ businessScope, businessScopeOrg }: any) => {
      if (!businessScope && !businessScopeOrg) {
        return '<div class="value">No records found</div>'
      }
      const businessScopeList = businessScope.map((item:string) => `<div>${item}</div>`)
      const businessScopeOrgList = businessScopeOrg.map((item:string) => `<div>${item}</div>`)
      return `<div class="value">${businessScopeList.join('')}${businessScopeOrgList.join('')}</div>`
    }
  }
])
export const taiwanContentInfo = reactive([
  {
    title: 'Basic Information',
    className: 'column-2',
    list: [
      {
        title: 'Registration Number:',
        key: 'registrationNumber',
        isCopy: true
      },
      {
        title: 'Date of Establishment:',
        value: ({ establishingDate }: any) => establishingDate
      },
      {
        title: 'Last Modification Date:',
        value: ({ lastUpdateDate }: any) => lastUpdateDate
      },
      {
        title: 'Date of Suspension Approved:',
        key: 'suspensionDate'
      },

      {
        title: 'Suspension Start Date:',
        key: 'suspensionBegin'
      },
      {
        title: 'Suspension End Date:',
        key: 'suspensionEnd'
      },
      {
        title: 'Suspension Status:',
        key: 'suspensionStatus' // 不确定
      },
      {
        title: 'Date of Cancellation:',
        key: 'cancellationDate'
      }
    ]
  },
  {
    title: 'Legal Representative',
    slot: 'legal'
  },
  {
    title: 'Status',
    htmlDomFunc: ({ status, statusOrg }: any) => {
      if (!status && !statusOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'status'
      },
      {
        title: '',
        key: 'statusOrg'
      }
    ]
  },
  {
    title: 'Company Size',
    list: [
      {
        title: 'Registered Capital:',
        value: ({ capital }: any) => ((+capital?.amount) ? `${capital.amount || ''} ${capital?.currency}` : undefined)
      },
      {
        title: 'Paid-Up Capital:',
        value: ({ paidInCapital }: any) => ((+paidInCapital?.amount) ? `${paidInCapital.amount || ''} ${paidInCapital?.currency}` : undefined)
      }
    ]
  },
  {
    title: 'Type',
    htmlDomFunc: ({ type, typeOrg }: any) => {
      if (!type && !typeOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'type'
      },
      {
        title: '',
        key: 'typeOrg'
      }
    ]
  },
  {
    title: 'Registration Authority',
    htmlDomFunc: ({ registerOffice, registerOfficeOrg }: any) => {
      if (!registerOffice && !registerOfficeOrg) {
        return '<div class="value">No records found</div>'
      }
      return false
    },
    list: [
      {
        title: '',
        key: 'registerOffice'
      },
      {
        title: '',
        key: 'registerOfficeOrg'
      }
    ]
  },
  {
    title: 'Contact Information',
    slot: 'contact-information',
    list: [
      {
        title: 'Phone Number: ',
        isCopy: true,
        key: 'phone'
      },
      {
        title: 'Fax: ',
        isCopy: true,
        key: 'fax'
      },
      {
        title: 'Address: '
      }
    ]
  },
  {
    title: 'Business Scope',
    htmlDomFunc: ({ businessScope, businessScopeOrg }: any) => {
      if (!businessScope && !businessScopeOrg) {
        return '<div class="value">No records found</div>'
      }
      const businessScopeList = businessScope.map((item:string) => `<div>${item}</div>`)
      const businessScopeOrgList = businessScopeOrg.map((item:string) => `<div>${item}</div>`)
      return `<div class="value">${businessScopeList.join('')}${businessScopeOrgList.join('')}</div>`
    }
  }
])
